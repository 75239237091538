import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
const appRoutes: Routes = [
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'termCondition', component: TermsConditionComponent },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },

    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      { path: 'error', loadChildren: () => import('../app/content/full-pages/error/error.module').then(m => m.ErrorModule)
      , canActivate: [AuthGuard] },
      {
        path: 'authentication', loadChildren: () => import('../app/content/full-pages/authentication/authentication.module').then(m =>
          m.AuthenticationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'others', loadChildren: () => import('../app/content/full-pages/others/others.module').then(m => m.OthersModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
      , canActivate: [AuthGuard] },

      //septer
      { path: 'septer/immo-objects', loadChildren: () => import('../app/content/septer/immo-objects/immo-objects.module').then(m => m.ImmoObjectsModule)
      , canActivate: [AuthGuard] },

      //application
      { path: 'application', loadChildren: () => import('../app/content/go4/application/application.module').then(m => m.ApplicationModule)
      , canActivate: [AuthGuard] },
      { path: 'listing', loadChildren: () => import('../app/content/go4/application/application.module').then(m => m.ApplicationModule)
      , canActivate: [AuthGuard] },

      //order
      { path: 'order', loadChildren: () => import('../app/content/order/order.module').then(m => m.OrderModule)
      , canActivate: [AuthGuard] },
      { path: 'personal', loadChildren: () => import('../app/content/resource/personal/personal.module').then(m => m.PersonalModule)
      , canActivate: [AuthGuard] },
      { path: 'vehicle', loadChildren: () => import('../app/content/resource/vehicle/vehicle.module').then(m => m.VehicleModule)
      , canActivate: [AuthGuard] },
      { path: 'backup', loadChildren: () => import('../app/content/common/backup/backup.module').then(m => m.BackupModule)
      , canActivate: [AuthGuard] },

       //crm
       { path: 'kunden', loadChildren: () => import('../app/content/crm/customer/customer.module').then(m => m.CustomerModule)
       , canActivate: [AuthGuard] },

      //cinema
      { path: 'components', loadChildren: () => import('../app/content/ngbbootstrap/components.module').then(m => m.ComponentsModule),
        canActivate: [AuthGuard]},
      { path: 'pos', loadChildren: () => import('../app/content/cinema/pos/pos.module').then(m => m.PosModule)
      , canActivate: [AuthGuard] },
      { path: 'cb', loadChildren: () => import('../app/content/cinema/cb/cb.module').then(m => m.CbModule)
      , canActivate: [AuthGuard] },
      { path: 'day-report', loadChildren: () => import('../app/content/cinema/day-report/day-report.module').then(m => m.DayReportModule)
      , canActivate: [AuthGuard] },
      { path: 'logs', loadChildren: () => import('../app/content/cinema/logs/logs.module').then(m => m.LogsModule)
      , canActivate: [AuthGuard] },
      { path: 'reservation', loadChildren: () => import('../app/content/cinema/reservation/reservation.module').then(m => m.ReservationModule)
      , canActivate: [AuthGuard] },
      { path: 'ticket', loadChildren: () => import('../app/content/cinema/ticket/ticket.module').then(m => m.TicketModule)
      , canActivate: [AuthGuard] },

      //goods
      { path: 'goods', loadChildren: () => import('../app/content/goods/goods.module').then(m => m.GoodsModule)
      , canActivate: [AuthGuard] },
      
      //theater
      { path: 'movie-manager', loadChildren: () => import('../app/content/theater/movie-manager/movie-manager.module').then(m => m.MovieManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'scheduler', loadChildren: () => import('../app/content/theater/scheduler/scheduler.module').then(m => m.SchedulerModule)
      , canActivate: [AuthGuard] },
      
      //admin
      { path: 'system-setting', loadChildren: () => import('../app/content/admin/system-setting/system-setting.module').then(m => m.SystemSettingModule)
      , canActivate: [AuthGuard] },
      { path: 'account-setting', loadChildren: () => import('../app/content/admin/account-setting/account-setting.module').then(m => m.AccountSettingModule)
      , canActivate: [AuthGuard] },
      { path: 'backup-manager', loadChildren: () => import('../app/content/admin/backup-manager/backup-manager.module').then(m => m.BackupManagerModule)
      , canActivate: [AuthGuard] },
      
      //base
      { path: 'base', loadChildren: () => import('../app/content/base/base.module').then(m => m.BaseModule)
      , canActivate: [AuthGuard] },
      { path: 'user-manager', loadChildren: () => import('../app/content/base/user-manager/user-manager.module').then(m => m.UserManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'ticket-manager', loadChildren: () => import('../app/content/base/ticket-manager/ticket-manager.module').then(m => m.TicketManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'suplier-manager', loadChildren: () => import('../app/content/base/suplier-manager/suplier-manager.module').then(m => m.SuplierManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'rentals-manager', loadChildren: () => import('../app/content/base/rentals-manager/rentals-manager.module').then(m => m.RentalsManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'product-manager', loadChildren: () => import('../app/content/base/product-manager/product-manager.module').then(m => m.ProductManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'bank-account-manager', loadChildren: () => import('../app/content/base/bank-account-manager/bank-account-manager.module').then(m => m.BankAccountManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'cinema', loadChildren: () => import('../app/content/base/cinema/cinema.module').then(m => m.CinemaModule)
      , canActivate: [AuthGuard] },

      //listing
      { path: 'archive', loadChildren: () => import('../app/content/listing/archive/archive.module').then(m => m.ArchiveModule)
      , canActivate: [AuthGuard] },
      { path: 'cashbook', loadChildren: () => import('../app/content/listing/cash-book/cash-book.module').then(m => m.CashBookModule)
      , canActivate: [AuthGuard] },
      { path: 'ffa-listing', loadChildren: () => import('../app/content/listing/ffa-listing/ffa-listing.module').then(m => m.FfaListingModule)
      , canActivate: [AuthGuard] },
      { path: 'gema-listing', loadChildren: () => import('../app/content/listing/gema-listing/gema-listing.module').then(m => m.GemaListingModule)
      , canActivate: [AuthGuard] },
      { path: 'grant-application', loadChildren: () => import('../app/content/listing/grant-application-listing/grant-application-listing.module').then(m => m.GrantApplicationListingModule)
      , canActivate: [AuthGuard] },
      { path: 'rental-listing', loadChildren: () => import('../app/content/listing/rental-listing/rental-listing.module').then(m => m.RentalListingModule)
      , canActivate: [AuthGuard] },
      { path: 'rentrak-listing', loadChildren: () => import('../app/content/listing/rentrak-listing/rentrak-listing.module').then(m => m.RentrakListingModule)
      , canActivate: [AuthGuard] },
      { path: 'ticket-listing', loadChildren: () => import('../app/content/listing/ticket-listing/ticket-listing.module').then(m => m.TicketListingModule)
      , canActivate: [AuthGuard] },

      { path: 'todo-app', loadChildren: () => import('../app/content/applications/todo-app/todo-app.module').then(m => m.TodoAppModule)
      , canActivate: [AuthGuard] },
      { path: 'chats', loadChildren: () => import('../app/content/applications/chat/chats.module').then(m => m.ChatsModule),
        canActivate: [AuthGuard]},
      { path: 'email', loadChildren: () => import('../app/content/applications/email/email.module').then(m => m.EmailModule)
      , canActivate: [AuthGuard] },
      {
        path: 'calender', loadChildren: () => import('../app/content/applications/calender/calender.module').then(m => m.CalenderModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'kanban', loadChildren: () => import('../app/content/applications/kanban-app/kanban-app.module').then(m => m.KanbanAppModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts', loadChildren: () => import('../app/content/common/contact/contact.module').then(m => m.ContactModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'treeview', loadChildren: () => import('../app/content/extra-components/treeview/treeview.module').then(m => m.TreeViewModule),
        canActivate: [AuthGuard]
      },
      { path: 'chartjs', loadChildren: () => import('../app/content/charts-maps/chartjs/chartjs.module').then(m => m.ChartjsModule)
      , canActivate: [AuthGuard] },
      {
        path: 'form-elements', loadChildren: () => import('../app/content/forms/form-elements/form-elements.module').then(m =>
          m.FormElementsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'form-layouts', loadChildren: () => import('../app/content/forms/form-layouts/form-layouts.module').then(m =>
          m.FormLayoutsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'form-wizard', loadChildren: () => import('../app/content/forms/form-wizard/form-wizard.module').then(m =>
          m.FormWizardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'form-repeater', loadChildren: () => import('../app/content/forms/form-repeater/form-repeater.module').then(m =>
          m.FormRepeaterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'ngchartist', loadChildren: () => import('../app/content/charts-maps/ngchartist/ngchartist.module').then(m =>
          m.NgchartistModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'boostraptables', loadChildren: () => import('../app/content/table/boostraptables/boostraptables.module').then(m =>
          m.BoostraptablesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datatables', loadChildren: () => import('../app/content/table/datatables/datatables.module').then(m => m.DatatablesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datatablesext', loadChildren: () => import('../app/content/table/datatablesext/datatablesext.module').then(m =>
          m.DatatablesextModule),
        canActivate: [AuthGuard]
      },
      { path: 'icons', loadChildren: () => import('../app/content/icons/icons.module').then(m => m.IconsModule), canActivate: [AuthGuard] },
      //{ path: 'cards', loadChildren: () => import('../app/content/cards/cards.module').then(m => m.CardsModule), canActivate: [AuthGuard] },
      { path: 'maps', loadChildren: () => import('../app/content/maps/maps.module').then(m => m.MapsModule), canActivate: [AuthGuard] },
      { path: 'invoice', loadChildren: () => import('../app/content/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      , canActivate: [AuthGuard] },
      {
        path: 'timelines', loadChildren: () => import('../app/content/pages/timelines/timelines.module').then(m => m.TimelinesModule),
        canActivate: [AuthGuard]
      },
      { path: 'user', loadChildren: () => import('../app/content/pages/user/user.module').then(m => m.UserModule)
      , canActivate: [AuthGuard] },
      { path: 'gallery', loadChildren: () => import('../app/content/pages/gallery/gallery.module').then(m => m.GalleryModule)
      , canActivate: [AuthGuard] },
      { path: 'news-feed', loadChildren: () => import('../app/content/pages/news-feed/news-feed.module').then(m => m.NewsFeedModule)
      , canActivate: [AuthGuard] },
      { path: 'dropzone', loadChildren: () => import('../app/content/pages/dropzone/dropzone.module').then(m => m.DropzoneModule)
      , canActivate: [AuthGuard] },
      {
        path: 'social-feed', loadChildren: () => import('../app/content/pages/social-feed/social-feed.module').then(m =>
          m.SocialFeedModule),
        canActivate: [AuthGuard]
      },
      { path: 'search', loadChildren: () => import('../app/content/pages/search/search.module').then(m => m.SearchModule)
      , canActivate: [AuthGuard] },
      {
        path: 'advanceCards', loadChildren: () => import('../app/content/advance-cards/advance-cards.module').then(m =>
          m.AdvanceCardsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'extraComponents', loadChildren: () => import('../app/content/extra-components/extra-components.module').then(m =>
          m.ExtraComponentsModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'changelog' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
