<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" id="customizer">
  <a [routerLink]="" class="customizer-close" (click)="toggleCustomizer($event)"><i class="feather ft-x font-medium-3"></i></a>
  <a [routerLink]="" class="customizer-toggle bg-danger box-shadow-3" (click)="toggleCustomizer($event)"><i
      class="feather ft-activity font-medium-3 white"></i></a>
  <div class="customizer-content p-2" fxFlex="auto" [perfectScrollbar]="config">
    <h4 class="text-uppercase mb-0">Aktionsmenü</h4>
    <hr>
    
  </div>
</div>
